import { Container, Grid, Paper, Box, Typography, Button , Link as MUILink} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FaxIcon from "@mui/icons-material/Fax";
import { Link } from "react-router-dom";

import logo from "../images/logo.png";
import { useSchoolInfo } from "../queries/schoolInfo";
import { AdminBar } from "./admin/Admin";

const pages = ["Αρχική", "Νέα & Υλικό", "Τμήματα", "Επικοινωνία"];
const routes = ["/home", "/content", "/classes", "/contact"];

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <br />
      <Button
        component={Link}
        to={`/home`}
        sx={{ textTransform: "none", padding: 0 }}
      >
        moragemoudance.gr
      </Button>{" "}
      <br />
      2022
    </Typography>
  );
}

export default function Footer() {
  const schoolInfo = useSchoolInfo();
  return (
    <>
      <AdminBar />
      <Paper
        sx={{
          width: "100%",
          height: "max-content",
          zIndex: 500,
          backgroundColor: "rgba(138, 123, 134, 0.8)",
          paddingBottom: { xs: 3 },
          paddingTop: { xs: 3 },
          bottom: 0,
          position: "relative",
        }}
        square
        component="footer"
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            justifyContent: { xs: "center" },
            alignItems: { xs: "center" },
          }}
        >
          <Box>
            <Box component="img" src={logo} sx={{ height: 350, widht: 100 }} />
          </Box>

          <Box
            sx={{
              padding: { xs: 2, sm: 5, md: 10 },
              display: "flex",
              //alignItems: "center",
              flexWrap: "wrap",
              borderRight: { md: "1px solid #DDDBDC" },
            }}
          >
            <Grid container spacing={0} direction="column" textAlign={"center"}>
              <Grid item>
                <Typography
                  sx={{ textTransform: "uppercase", color: "#DDDBDC" }}
                >
                  Σελιδες
                </Typography>
              </Grid>

              {pages.map((page, index) => (
                <Grid item key={page}>
                  <Button
                    component={Link}
                    to={`${routes[index]}`}
                    sx={{ textTransform: "none" }}
                  >
                    <Typography color="white">{page}</Typography>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box
            sx={{
              padding: { xs: 2, sm: 5, md: 10 },
              borderBottom: { xs: "1px solid #DDDBDC", md: "none" },
            }}
          >
            <Grid container spacing={2} direction="column" textAlign={"center"}>
              <Grid item>
                <Typography
                  sx={{ textTransform: "uppercase", color: "#DDDBDC" }}
                >
                  Επικοινωνια
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="#DDDBDC">
                  <PhoneIcon
                    sx={{ verticalAlign: "middle", display: "inline-flex" }}
                  />{" "}
                  {schoolInfo.data?.phone_number}{" "}
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="#DDDBDC">
                  <FaxIcon
                    sx={{ verticalAlign: "middle", display: "inline-flex" }}
                  />{" "}
                  {schoolInfo.data?.fax_number}
                </Typography>
              </Grid>

              <Grid item>
                <Typography color="#DDDBDC">
                  <AlternateEmailIcon
                    sx={{ verticalAlign: "middle", display: "inline-flex" }}
                  />{" "}
                  {schoolInfo.data?.email}
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="#DDDBDC">
                  <LocationOnIcon
                    sx={{ verticalAlign: "middle", display: "inline-flex" }}
                  />
                  {schoolInfo.data?.address}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <br />
        <Copyright />
        <Box sx={{ width: "100%", textAlign: "center" }} >
          <MUILink href="/admin/" underline='hover' variant=""><Typography>Διαχειριση</Typography></MUILink>
          {/* <Button variant="info" onClick={()=> window.location.pathname('test')}> 
            <Typography>Διαχειριση</Typography>
          </Button> */}
        </Box>
      </Paper>
    </>
  );
}
