import axios from "axios";
import { useQuery } from "react-query";
import { useMutation } from "react-query";

export const useMedia = (page, itemId= "") =>
  useQuery(
    ["media", page, itemId],
    () =>
      axios
        .get(`/api/media/${itemId}`,{params:{page}})
        .then(({ data }) => data),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

export const useUpdateMedia = (onSuccess, onError) =>
  useMutation(
    (formData, token) =>
      axios
        .patch("/api/media/", formData, {
          headers: { token: `Bearer ${token}` },
        })
        .then(({ data }) => data),
    {
      onSuccess,
      onError,
    }
  );
