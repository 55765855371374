import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Link as MUILink } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link, useLocation } from "react-router-dom";

import logo from "../images/logoTransparent.png";
import "../styles/buttonGradualUnderline.css";
import "../styles/menuButton.css";
import "../styles/colorscheme.css";
import { useSchoolInfo } from "../queries/schoolInfo";
import { AdminBar } from "./admin/Admin";

const pages = ["Αρχική", "Νέα & Υλικό", "Τμήματα", "Επικοινωνία"];
const routes = ["/home", "/content", "/classes", "/contact"];

function SiteMenu() {
  const location = useLocation();
  const schoolInfo = useSchoolInfo();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position={location.pathname === "/home" ? "absolute" :  "relative"}
      sx={{
        backgroundColor: location.pathname === "/home" ? "rgba(221, 219, 220,0.8)" : "rgba(221, 219, 220,1)",
        border:"none",
        boxShadow:"none",
        zIndex: 200,
      }}
    >
      <Container maxWidth="xl" sx={{ border: "none" }}>
        <Toolbar disableGutters>
          <Box
            component="img"
            src={logo}
            sx={{
              display: { xs: "none", md: "flex" },
              mr: 0,
              height: { xs: 120, md: 170 },
              marginLeft: "auto",
              marginRight: 0,
            }}
          />
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="logo"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "rgb(137, 72, 77)" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
              MenuListProps={{ disablePadding: true }}
            >
              {pages.map((page, index) => (
                <MenuItem
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{
                    backgroundColor: "#DDDBDC",
                    padding: 3,
                    "&:hover": { backgroundColor: "#DDDBDC" },
                  }}
                  component={Link}
                  to={`${routes[index]}`}
                >
                  <Typography
                    textAlign="center"
                    fontSize={22}
                    variant="menuLink"
                    className={`gradual-underline link ${
                      location.pathname === routes[index] ? "link-active" : ""
                    }`}
                  >
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* browser view */}

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page, index) => (
              <Button
                key={page}
                className={`gradual-underline link vusillusFont ${
                  location.pathname === routes[index] ? "link-active" : ""
                }`}
                onClick={handleCloseNavMenu}
                component={Link}
                to={`${routes[index]}`}
                sx={{
                  my: 2,
                  display: "block",
                  textTransform: "none",
                  fontSize: 25,
                }}
              >
                <Typography variant="menuLink">{page}</Typography>
              </Button>
            ))}
          </Box>

          <Box sx={{ marginLeft: "auto", display: "inline-flex", mr: 2 }}>
            <MUILink
              href={schoolInfo.data?.facebook_url}
              sx={{
                color: "rgba(138, 123, 134, 1)",
                my: 5,
                transition: "all 0.2s linear",
                "&:hover": {
                  color: "#dddbdc",
                  backgroundColor: "#1878F2",
                },
                marginRight: 1,
                padding: 1,
              }}
            >
              <Typography>
                <FacebookIcon
                  sx={{
                    fontSize: 25,
                    verticalAlign: "middle",
                    display: "inline-flex",
                  }}
                />
              </Typography>
            </MUILink>

            <MUILink
              href={schoolInfo.data?.youtube_channel_url}
              sx={{
                color: "rgba(138, 123, 134, 1)",
                my: 5,
                transition: "all 0.2s linear",
                "&:hover": {
                  color: "#dddbdc",
                  backgroundColor: "#FF0000",
                },
                padding: 1,
              }}
            >
              <Typography>
                <YouTubeIcon
                  sx={{
                    fontSize: 25,
                    verticalAlign: "middle",
                    display: "inline-flex",
                  }}
                />
              </Typography>
            </MUILink>

            <MUILink
              href={schoolInfo.data?.instagram_url}
              sx={{
                my: 5,
                padding: 1,
                marginLeft: 1,
                color: "rgba(138, 123, 134, 1)",
                transition: "all 0.1s linear",
                "&:hover": {
                  color: "#dddbdc",
                  background:
                    "linear-gradient(45deg,#f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)",
                  filter:
                    "progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 )",
                },
              }}
            >
              <Typography>
                <InstagramIcon
                  sx={{
                    fontSize: 25,
                    verticalAlign: "middle",
                    display: "inline-flex",
                  }}
                />
              </Typography>
            </MUILink>
            <Box
              component="img"
              src={logo}
              sx={{
                display: { xs: "flex", md: "none" },
                mr: 0,
                height: { xs: 120, md: 170 },
                marginLeft: "auto",
                marginRight: 0,
              }}
            />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default SiteMenu;
