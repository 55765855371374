import { motion } from "framer-motion";

import { Paper, Typography, Box, Button } from "@mui/material";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Slider from "react-slick";

import { Container } from "@mui/system";

import videoBg from "../videos/backgroundVideo.mp4";



function NewsSlider() {
  const NextArrow = (props) => {
    const { style, onClick } = props;
    return (
      <Button
        onClick={onClick}
        sx={{
          ...style,
          position: "absolute",
          zIndex: 200,
          top: "50%",
          right: -50,
          transform: "translate(0,-50%)",
          padding: 0,
          margin: 0,
          width: "max-content",
          height: "max-content",
          lineHeight: "100%",
          color: "#9A8E97",
        }}
      >
        <ArrowForwardIosIcon />
      </Button>
    );
  };

  const PreviousArrow = (props) => {
    const { style, onClick } = props;
    return (
      <Button
        onClick={onClick}
        sx={{
          ...style,
          position: "absolute",
          zIndex: 200,
          top: "50%",
          left: -50,
          transform: "translate(0,-50%)",
          padding: 0,
          margin: 0,
          width: "max-content",
          height: "max-content",
          lineHeight: "100%",
          color: "#9A8E97",
        }}
      >
        <ArrowBackIosNewIcon />
      </Button>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
  };
  return (
    <Box sx={{ margin: 3 }}>
      <Slider {...settings}>
        <Box>
          <Typography variant="h5">
            Ξεκινάμε λοιπόν αυτή τη νέα χρονιά!{" "}
          </Typography>
          Με καινούργιους <b>σπουδαστές</b>, καινούργιους <b>στόχους</b> να
          πραγματοποιήσουμε, καινούργια <b>όνειρα</b> να υποστηρίξουμε και…{" "}
          <b>καινούργιο όνομα</b>!
          <br />
          <br />
          Σχολή Μοραγέμου λοιπόν για να τιμήσουμε την κυρία Βούλα μας που για
          τριάντα χρόνια διοίκησε τη σχολή ως μοναδική συνεχίστρια του
          εκπαιδευτικού έργου της <i>Ραλλούς Μάνου</i>.
          <br />
          <br />
          Μας παραδίδει τα ηνία τώρα, σε μας που όλα αυτά τα χρόνια είμαστε
          δίπλα της και στηρίζουμε το ιστορικό αυτό σχολείο αυτής της υπέροχα
          δύσκολης Τέχνης!
          <br />
          Ανανεώνουμε την υπόσχεση μας να κάνουμε το καλύτερο δυνατόν για τα
          παιδιά μας διατηρώντας το κέφι μας και τη διάθεση μας για καινοτόμα
          πράγματα.
          <br />
          <br />
          <i>Δημήτρης Μωραγέμος, Αλίκη Καζούρη</i>
        </Box>
      </Slider>
    </Box>
  );
}

function HomeVideo() {
  return (
    <Box sx={{ height: "70vh", width: "100%", top: 0 }}>
      <video
        style={{ height: "100%", width: "100%", objectFit: "cover" }}
        src={videoBg}
        autoPlay
        loop
        muted
        disablePictureInPicture
        x-webkit-airplay="deny"
        disableRemotePlayback
        playsInline
      />
    </Box>
  );
}

export default function Home() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        height: "max-content",
      }}
    >
      <HomeVideo />
      <Container
        position={"relative"}
        sx={{ height: "max-content", marginTop: 3 }}
        disableGutters
      >
        <NewsSlider />
      </Container>
      <Container
        position={"relative"}
        sx={{ height: "max-content", marginTop: 3 }}
      >
        <Paper
          className="home"
          square={true}
          elevation={0}
          display="flex"
          sx={{
            position: "relative",
            overflow: "hidden",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "max-content",
          }}
        ></Paper>
      </Container>
    </motion.div>
  );
}
