import { motion } from "framer-motion";

import { Typography, Box,  Grid, Button } from "@mui/material";
import { Container } from "@mui/system";

import { Link, Outlet, useLocation } from "react-router-dom";

import background from "../images/news_and_media_bg.jpg";

export default function Content() {
  const location = useLocation()
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box
        sx={{
          //position: "relative",
          height: "max-content",
          width: "100%",
          top: 0,
          backgroundSize: "cover",
          backgroundRepeat: "repeat-x",
          backgroundImage: `url(${background})`,
          backgroundPosition: { md: "0px -300px" },
          backgroundColor: "black",
        }}
      >
        <Container>
          <Grid
            container
            sx={{
              height: "100%",
              width: "100%",
              top: { xs: 120, md: 170 },
              //position: "relative",
              padding: 1,
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Grid item md={4} xs={12}>
              <Box
                sx={{
                  height: { xs: 121, md: 312 },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="pageLink"
                  sx={{
                    background: "rgba(0,0,0,0.8)",
                    border: "none",
                    paddingTop: 5,
                    paddingBottom: 5,
                    transition: ".1s all linear",
                    "&:hover": {
                      color: "white !important",
                    },
                  }}
                >
                  <Button
                    component={Link}
                    variant={"pageLink"}
                    to="/content/people"
                    sx={{
                      textTransform: "none",
                      color:
                        location.pathname.includes("people")
                          ? "white !important"
                          : "grey !important",
                      underline: "none",
                      padding: 0,
                      transition: ".1s all linear",
                      font: "inherit",
                      "&:hover": {
                        color: "white !important",
                      },
                    }}
                  >
                    Οι <br />
                    άνθρωποι
                  </Button>
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box
                sx={{
                  height: { xs: 121, md: 312 },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="pageLink"
                  sx={{
                    background: "rgba(0,0,0,0.8)",
                    border: "none",
                    paddingTop: 5,
                    paddingBottom: 5,
                    transition: ".1s all linear",
                    "&:hover": {
                      color: "white !important",
                    },
                  }}
                >
                  <Button
                    component={Link}
                    variant={"pageLink"}
                    to="/content/news"
                    sx={{
                      textTransform: "none",
                      color:
                      location.pathname.includes("news")
                          ? "white !important"
                          : "grey !important",
                      underline: "none",
                      padding: 0,
                      transition: ".1s all linear",
                      font: "inherit",
                      "&:hover": {
                        color: "white !important",
                      },
                    }}
                  >
                    Νέα & <br />
                    Ανακοινώσεις
                  </Button>
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box
                sx={{
                  height: { xs: 121, md: 312 },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="pageLink"
                  sx={{
                    background: "rgba(0,0,0,0.8)",
                    border: "none",
                    paddingTop: 5,
                    paddingBottom: 5,
                    transition: ".1s all linear",
                    "&:hover": {
                      color: "white !important",
                    },
                  }}
                >
                  <Button
                    component={Link}
                    variant={"pageLink"}
                    to="/content/media"
                    sx={{
                      textTransform: "none",
                      color:
                      location.pathname.includes("media")
                          ? "white !important"
                          : "grey !important",
                      underline: "none",
                      padding: 0,
                      transition: ".1s all linear",
                      font: "inherit",
                      "&:hover": {
                        color: "white !important",
                      },
                    }}
                  >
                    Video & <br /> Φωτογραφίες
                  </Button>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Outlet />
    </motion.div>
  );
}
