import { Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { motion } from "framer-motion";

import NotFoundImage from "../images/404.png";

import '../styles/fonts.css'

export default function NotFound() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Container sx={{ textAlign: "center" , height:'max-content'}}>
        <Typography className="tinos">Η σελίδα που ψάχνετε δεν βρέθηκε</Typography>
        <Box sx={{height:{xs:300,md:500}}} >
          <img  height={"100%"} src={NotFoundImage} alt="not-found"/>
        </Box>
      </Container>
    </motion.div>
  );
}
