import axios from "axios";
import { useQuery } from "react-query";
import { useMutation } from "react-query";

export const useSchoolInfo = () =>
  useQuery(['school_info'], () => axios.get(`/api/school/`).then(({ data }) => data), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });


export const useUpdateSchoolInfo = (onSuccess, onError) => 
  useMutation(
    (formData, token) => axios.patch("/api/school/", formData, {headers: {token: `Bearer ${token}`}}).then(({ data }) => data),
    {
      onSuccess,
      onError
    }
  );