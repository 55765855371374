import "leaflet/dist/leaflet.css";
import "../styles/leaflet.css";
import "../styles/buttonGradualUnderline.css";
import "../styles/fonts.css";
import "../styles/colorscheme.css";

import logoTransparent from "../images/logoTransparent.png";
import L from "leaflet";

import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Container,
  Link as MUILink,
  Grid,
  Box,
  TextField,
  Button,
} from "@mui/material";
import { motion } from "framer-motion";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { MobileView, BrowserView } from "react-device-detect";
import reCAPTCHA from "react-google-recaptcha";
import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FaxIcon from "@mui/icons-material/Fax";
import { useSchoolInfo } from "../queries/schoolInfo";
import SendIcon from "@mui/icons-material/Send";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";

import { useRef } from "react";
import axios from "axios";
import { useSendEmail } from "../queries/contact";
import { useState } from "react";

const mapbox_url = `https://api.mapbox.com/styles/v1/${process.env.REACT_APP_MAPBOX_USERNAME}/${process.env.REACT_APP_MAPBOX_STYLE_ID}/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;

const icon = new L.icon({
  iconUrl: logoTransparent,
  iconRetinaUrl: logoTransparent,
  iconAnchor: [37.9401184, 23.7400301],
  popupAnchor: [0, 0],
  shadowUrl: [37.9401184, 23.7400301],
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(35, 40),
  className: "leaflet-div-icon",
});

function Map({ x, y }) {
  return (
    <MapContainer
      center={[37.9401184, 23.7400301]}
      zoom={18}
      style={{ height: 500, width: "100%" }}
      attributionControl="none"
      scrollWheelZoom={false}
    >
      <TileLayer url={mapbox_url} />
      <Marker position={[37.9401184, 23.7400301]} icon={icon}>
        <Popup icon={icon}>Ι. Μεταξά 4, Αγ. Δημήτριος Αττικής</Popup>
      </Marker>
    </MapContainer>
  );
}

function ContanctForm() {
  const userEmailRef = useRef();
  const emailBodyRef = useRef();
  const subjectRef = useRef();

  const onSuccess = () => {
    setButtonColor("success");
  };

  const onError = () => {
    setbuttonState(false);
    setButtonColor("error");
    setTimeout(() => {
      setButtonColor("info");
    }, 500);
  };

  const sendMailMutation = useSendEmail(onSuccess, onError);

  const [buttonState, setbuttonState] = useState(false);
  const [buttonColor, setButtonColor] = useState("info");

  const handleSubmit = () => {
    setbuttonState(true);
    sendMailMutation.mutate({
      email: userEmailRef.current,
      body: emailBodyRef.current,
      subject: subjectRef.current,
    });
  };

  return (
    <Box
      sx={{
        height: 600,
        width: "100%",
        textAlign: { xs: "center", md: "left" },
      }}
      key="contact-form"
    >
        <Typography
          fontSize={35}
          variant="h5"
          color="rgba(138, 123, 134, 1)"
          sx={{ borderBottom: "1px solid rgba(138, 123, 134, 0.5)" }}
        >
          Αποστολή μηνύματος
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          id="user-email"
          label="E-mail"
          name="email"
          autoComplete="email"
          variant="outlined"
          onChange={(e) => (userEmailRef.current = e.target.value)}
          error={!!sendMailMutation.error?.response.data?.["email"]}
          helperText={sendMailMutation?.error?.response.data?.["email"]}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email-subject"
          label="Θέμα"
          name="subject"
          variant="outlined"
          onChange={(e) => (subjectRef.current = e.target.value)}
          error={!!sendMailMutation.error?.response.data?.["subject"]}
          helperText={sendMailMutation?.error?.response.data?.["subject"]}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="content"
          label="Περιεχόμενο μηνύματος"
          id="email-content"
          variant="outlined"
          multiline
          onChange={(e) => (emailBodyRef.current = e.target.value)}
          rows={10}
          error={!!sendMailMutation.error?.response.data?.["body"]}
          helperText={sendMailMutation?.error?.response.data?.["body"]}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color={buttonColor}
          sx={{
            mt: 3,
            mb: 2,
            textTransform: "none",
            backgroundColor: "rgb(137, 72, 77)",
            "&:hover": {
              backgroundColor: "rgb(137, 72, 77)",
              boxShadow:
                "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
            },
          }}
          onClick={handleSubmit}
          disabled={buttonState}
        >
          {sendMailMutation.isIdle || sendMailMutation.isError ? (
            <>
              <Typography color={"white"}>
                <b>Αποστολή μηνύματος</b>
              </Typography>
              <SendIcon sx={{ m: 1, color: "white" }} />
            </>
          ) : null}
          {sendMailMutation.isLoading ? (
            <>
              <Typography color={"white"}>
                <b>Το μήνυμα σας στένλεται</b>
              </Typography>
              <CircularProgress
                sx={{ m: 1, color: "white" }}
                color="inherit"
                size={25}
              />
            </>
          ) : null}
          {sendMailMutation.isSuccess ? (
            <>
              <Typography color={"white"}>
                <b>Το μήνυμα σας στάλθηκε με επιτυχία</b>
              </Typography>

              <CheckIcon sx={{ m: 1, color: "white" }} />
            </>
          ) : null}
        </Button>
    </Box>
  );
}

export default function Contact() {
  const schoolInfo = useSchoolInfo();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Container
        sx={{
          marginTop: 5,
          marginBottom: 5,
          position: "relative",
          display: "flex",
          justifyContent: "center",
          flexFlow: "wrap",
        }}
      >
        <Box
          sx={{
            marginTop: 4,
            borderBottom: "1px solid rgba(138, 123, 134, 0.5)",
            width: "100%",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography fontSize={35} variant="h5" color="rgba(138, 123, 134, 1)">
            Επικοινωνία
          </Typography>
        </Box>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item md={4} xs={12}>
            <Card
              elevation={0}
              sx={{ textAlign: "center", backgroundColor: "#DDDBDC" }}
            >
              <CardContent>
                <Typography color={"rgb(90, 30, 33)"}>
                  <PhoneIcon
                    sx={{ verticalAlign: "middle", display: "inline-flex" }}
                  />
                  Τηλέφωνο
                </Typography>
                <br />
                <Typography variant="h6">
                  {schoolInfo.data?.phone_number}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={4} xs={12}>
            <Card
              elevation={0}
              sx={{ textAlign: "center", backgroundColor: "#DDDBDC" }}
            >
              <CardContent>
                <Typography color={"rgb(90, 30, 33)"}>
                  <AlternateEmailIcon
                    sx={{ verticalAlign: "middle", display: "inline-flex" }}
                  />{" "}
                  E-mail
                </Typography>
                <br />
                <Typography variant="h6">{schoolInfo.data?.email}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={4} xs={12}>
            <Card
              elevation={0}
              sx={{ textAlign: "center", backgroundColor: "#DDDBDC" }}
            >
              <CardContent>
                <Typography color={"rgb(90, 30, 33)"}>
                  <FaxIcon
                    sx={{ verticalAlign: "middle", display: "inline-flex" }}
                  />{" "}
                  Fax
                </Typography>
                <br />
                <Typography variant="h6">
                  {schoolInfo.data?.fax_number}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item md={24} xs={12}>
            <Card
              elevation={0}
              sx={{ textAlign: "center", backgroundColor: "#DDDBDC" }}
            >
              <CardContent>
                <Typography color={"rgb(90, 30, 33)"}>
                  <LocationOnIcon
                    sx={{ verticalAlign: "middle", display: "inline-flex" }}
                  />{" "}
                  Διεύθηνση
                </Typography>
                <br />
                <Typography variant="h6">
                  {" "}
                  {schoolInfo.data?.address}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Box
          sx={{
            marginTop: 4,
            borderBottom: "1px solid rgba(138, 123, 134, 0.5)",
            width: "100%",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography fontSize={35} variant="h5" color="rgba(138, 123, 134, 1)">
            Χάρτης
          </Typography>
        </Box>
        <Card
          sx={{
            width: "100%",
            marginTop: 2,
            marginBottom: 5,
            paddingBottom: 2,
            backgroundColor: "#DDDBDC",
          }}
          elevation={0}
        >
          <CardMedia>
            <Map />
          </CardMedia>
          <CardContent>
            <Typography gutterBottom variant="h5">
              {schoolInfo.data?.address}
            </Typography>
            <Typography>{schoolInfo.data?.address_description}</Typography>
          </CardContent>
          <CardActions>
            <BrowserView>
              <MUILink
                href={schoolInfo.data?.google_maps_url}
                target="_blank"
                sx={{ textDecoration: "none" }}
              >
                <Typography
                  color={"#DDDBDC"}
                  sx={{
                    borderRadius: 2,
                    padding: 2,
                    backgroundColor: "rgb(137, 72, 77)",
                    display: "flex",
                    boxShadow:
                      "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
                  }}
                >
                  <b>Βρές το στο Google Maps</b>{" "}
                  <MapOutlinedIcon sx={{ ml: 1 }} />
                </Typography>
              </MUILink>
            </BrowserView>
            <MobileView>
              <MUILink
                href={schoolInfo.data?.google_maps_url}
                target="_blank"
                sx={{
                  padding: 1,
                  fontWeight: 15,
                  borderRadius: 2,
                  textDecoration: "none",
                  color: "#DDDBDC",
                  background: "rgb(137, 72, 77)",
                }}
              >
                Άνοιγμα στους χάρτες
              </MUILink>
            </MobileView>
          </CardActions>
        </Card>
        <ContanctForm />
      </Container>
    </motion.div>
  );
}
