import { motion } from "framer-motion";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Paper from "@mui/material/Paper";
import { Container } from "@mui/system";
import { Box, Grid, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

import background from "../images/classes_bg.jpg";

const schedules = [
  {
    schedule_name: "Ερασιτεχνικά και παιδικά τμήματα",
    slug: "erasitexnika-kai-paidika-tmhmata-2022-2023",
    year: "2022-2023",
    days: {
      Δευτέρα: [
        {
          class_name: "Κλασσικό εφηβικό",
          instructor: "Λίνα Λυκοπούλου",
          hours: "18:30 - 20:00",
        },
      ],
      Τρίτη: [
        {
          class_name: "Μπαλέτο Α' + Β'",
          instructor: "Αίγλη Σαρλάνη",
          hours: "17:30 - 18:30",
        },
        {
          class_name: "Μπαλέτο Γ'",
          instructor: "Αίγλη Σαρλάνη",
          hours: "18:30 - 19:30",
        },
        {
          class_name: "Σύγχρονο Παιδικό",
          instructor: "Γεωργία Τασιούλα",
          hours: "19:30 - 20:30",
        },
      ],
      Τετάρτη: [
        {
          class_name: "Κλασσικό Εφηβικό",
          instructor: "Λίνα Λυκοπούλου",
          hours: "18:30 - 20:00",
        },
      ],
      Πέμπτη: [
        {
          class_name: "Μπαλέτο Α' + Β'",
          instructor: "Αίγλη Σαρλάνη",
          hours: "17:30 - 18:30",
        },
        {
          class_name: "Μπαλέτο Γ'",
          instructor: "Αίγλη Σαρλάνη",
          hours: "18:30 - 19:30",
        },
      ],
      Παρασκευή: [
        {
          class_name: "Σύγχρονο Παιδικό",
          instructor: "Γεωργία Τασιούλα",
          hours: "19:00 - 20:00",
        },
      ],
    },
  },
  {
    schedule_name:
      "Προπαρασκευαστικό τμήμα εισαγωγικών εξετάσεων στις επαγγελματικές σχολές χορού",

    slug: "proepaggelmatiko-tmhma-eisagogikon-eksetasewn-stis-epaggelmatikes-sxoles-xorou",
    year: "2022-2023",
    days: {
      Δευτέρα: [
        {
          class_name: "Κλασικό προετοιμασία φυτώριο",
          instructor: "ΜΙΜΗ ΑΝΤΩΝΑΚΗ",
          hours: "18:00 - 19:30",
        },
        {
          class_name: "Σύγχρονο φυτώριο - προετοιμασία",
          instructor: "ΑΓΓΕΛΟΣ ΑΝΤΩΝΑΚΟΣ",
          hours: "19:30 - 21:00",
        },
      ],
      Τρίτη: [
        {
          class_name: "Κλασικό προετοιμασία",
          instructor: "ΑΡΕΤΗ ΠΑΛΟΥΚΗ",
          hours: "18:00 - 19:30",
        },
        {
          class_name: "Σύγχρονο φυτώριο - προετοιμασία",
          instructor: "ΑΝΤΡΙΑ ΔΗΜΗΤΡΙΟΥ",
          hours: "19:30 - 21:00",
        },
      ],
      Τετάρτη: [
        {
          class_name: "Κλασικό προετοιμασία φυτώριο",
          instructor: "ΜΙΜΗ ΑΝΤΩΝΑΚΗ",
          hours: "18:00 - 19:30",
        },
        {
          class_name: "Σύγχρονο φυτώριο - προετοιμασία",
          instructor: "ΑΓΓΕΛΟΣ ΑΝΤΩΝΑΚΟΣ",
          hours: "19:30 - 21:00",
        },
      ],
      Πέμπτη: [
        {
          class_name: "Κλασικό προετοιμασία",
          instructor: "ΑΡΕΤΗ ΠΑΛΟΥΚΗ",
          hours: "18:00 - 19:30",
        },
        {
          class_name: "Σύγχρονο φυτώριο - προετοιμασία",
          instructor: "ΑΝΤΡΙΑ ΔΗΜΗΤΡΙΟΥ",
          hours: "19:30 - 21:00",
        },
      ],
      Παρασκευή: [
        {
          class_name: "Κλασικό προετοιμασία - επαγγελματίες",
          instructor: "ΑΡΕΤΗ ΠΑΛΟΥΚΗ",
          hours: "18:00 - 19:30",
        },
        {
          class_name: "Σύγχρονο προετοιμασία",
          instructor: "ΑΓΓΕΛΟΣ ΑΝΤΩΝΑΚΟΣ",
          hours: "19:30 - 21:00",
        },
      ],
    },
  },
];

export default function Classes() {
  const navigate = useNavigate();
  const [openSchedule, setOpenSchedule] = useState();
  const schedulesRef = useRef([]);

  useEffect(() => {
    schedulesRef.current = schedulesRef.current.slice(0, schedules.length);
  }, [schedules.items]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ zIndex: 300 }}
    >
      <Box
        sx={{
          //position: "relative",
          // height: "max-content",
          minHeight: "312px",
          width: "100%",
          top: 0,
          backgroundSize: "cover",
          backgroundRepeat: "repeat-x",
          backgroundImage: `url(${background})`,
          backgroundPosition: {
            xs:"0px -50px",
            sm: "0px -200px",
            md: "0px -400px",
            lg: "0px -600px",
            xl: "0px -1000px",
          },
          backgroundColor: "black",
        }}
      >
        Ok
      </Box>
      {/* <Box sx={{ height: "90vh", width: "100%", top: 0 }}>
        <img
          src={background}
          style={{ height: "100%", width: "100%", objectFit: "cover" }}
        />
      </Box> */}
      <Container
        sx={{
          marginTop: 5,
          marginBottom: 5,
          display: "flex",
          justifyContent: "center",
          flexFlow: "wrap",
          zIndex: 300,
        }}
      >
        {schedules.map((schedule, index) => {
          if (window.location.pathname.indexOf(schedule.slug) > 1) {
            schedulesRef.current[index]?.scrollIntoView({
              behavior: "smooth",
              block: "start",
              top: -5,
            });
          }

          return (
            <Paper
              key={`schedule-${index}`}
              sx={{ width: "100%", marginBottom: 4 }}
              elevation={1}
            >
              <Accordion
                sx={{ backgroundColor: "#DDDBDC" }}
                ref={(el) => (schedulesRef.current[index] = el)}
                expanded={
                  window.location.pathname.indexOf(schedule.slug) > 1
                    ? true
                    : false
                }
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ width: "100%" }}
                  onClick={() => {
                    if (openSchedule === schedule.slug) {
                      setOpenSchedule();
                      navigate(`/classes`);
                    } else {
                      setOpenSchedule(schedule.slug);
                      navigate(`/classes/${schedule.slug}/`);
                      schedulesRef.current[index].scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        top: -50,
                      });
                    }
                  }}
                >
                  <Typography variant="h5" width="100%">
                    {schedule.schedule_name} ({schedule.year})
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid container>
                    {Object.keys(schedule.days).map((day, index) => (
                      <Grid
                        item
                        key={`day-${index}`}
                        textAlign="center"
                        xs={12}
                        md
                        sx={{
                          borderRight: {
                            md:
                              index + 1 !== Object.keys(schedule.days).length
                                ? "1px solid #cfcece"
                                : "none",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor:
                              index % 2 === 0 ? "#9B8F98" : "#cfcece",
                          }}
                        >
                          <Typography
                            variant="h5"
                            color={index % 2 !== 0 ? "#9B8F98" : "#dddbdc"}
                          >
                            <b>{day}</b>
                          </Typography>
                        </Box>
                        {schedule.days[day].map((info, index) => (
                          <Box
                            key={`hour-${index}`}
                            sx={{
                              marginTop: 1,
                              paddingBottom: 1,
                              borderBottom:
                                index + 1 !== schedule.days[day].length
                                  ? "1px solid #cfcece"
                                  : "none",
                            }}
                          >
                            <Typography>{info.hours}</Typography>

                            <Typography variant="h6">
                              <b>{info.class_name}</b>
                            </Typography>

                            <Typography>{info.instructor}</Typography>
                          </Box>
                        ))}
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Paper>
          );
        })}
      </Container>
    </motion.div>
  );
}
