import axios from "axios";
import { useQuery } from "react-query";
import { useMutation } from "react-query";

export const useArticles = (page, itemId = "") =>
  useQuery(
    ["articles", page, itemId],
    () =>
      axios
        .get(`/api/articles/${itemId}`, { params: { page } })
        .then(({ data }) => data),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

export const useUpdateMedia = (onSuccess, onError) =>
  useMutation(
    (formData, token) =>
      axios
        .patch("/api/articles/", formData, {
          headers: { token: `Bearer ${token}` },
        })
        .then(({ data }) => data),
    {
      onSuccess,
      onError,
    }
  );
