import { createContext, useState } from "react";

import { Routes, Route, Navigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { CssBaseline } from "@mui/material";

import Footer from "./components/Footer";
import Contact from "./components/Contact";
import Home from "./components/Home";
import SiteMenu from "./components/SiteMenu";
import Content from "./components/Content";
import NotFound from "./components/NotFound";
import Classes from "./components/Classes";
import News, { NewsDetails } from "./components/News";
import { Media, MediaDetails } from "./components/Media";
import { People, PeopleDetails } from "./components/People";

import { useSchoolInfo } from "./queries/schoolInfo";
// import Admin , {AdminBar}from "./components/admin/Admin";

import { ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";

import { theme } from "./styles/theme";

import axios from "axios";
// import AdminDashboard from "./components/admin/AdminDashboard";
// import AdminSchoolInfo from "./components/admin/AdminSchoolInfo";
// import AdminPeople, { AdminPeopleEdit } from "./components/admin/AdminPeople";
// import AdminArticles from "./components/admin/AdminArticles";
// import AdminMedia from "./components/admin/AdminMedia";

export const AdminContext = createContext(null);

if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = process.env.REACT_APP_PUBLIC_API_URL;
}

function App() {
  const schoolInfo = useSchoolInfo();
  const [adminIsLoggedIn, setAdminIsLoggedIn] = useState({
    loggedIn: false,
    token: null,
  });

  return (
    <>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
            <AdminContext.Provider
              value={{ adminIsLoggedIn, setAdminIsLoggedIn }}
            >
              <CssBaseline />

              <SiteMenu schoolInfo={schoolInfo} />

              <AnimatePresence>
                <Routes>
                  <Route path="/" element={<Navigate replace to="/home" />} />

                  <Route index path="/home" element={<Home />} />

                  <Route path="/classes/*" element={<Classes />} />

                  <Route
                    path="/contact"
                    element={<Contact schoolInfo={schoolInfo} />}
                  />

                  <Route path="/content" element={<Content />}>
                    <Route path="/content/news" element={<News />} />
                    <Route path="/content/news/*" element={<NewsDetails />} />
                    <Route path="/content/people" element={<People />} />
                    <Route
                      path="/content/people/*"
                      element={<PeopleDetails />}
                    />
                    <Route path="/content/media" element={<Media />}>
                      <Route
                        path="/content/media/*"
                        element={<MediaDetails />}
                      />
                    </Route>
                  </Route>

                  {/* <Route path="/admin" element={<Admin />}>
                  <Route path="/admin/dashboard" element={<AdminDashboard />}>
                    <Route
                      path="/admin/dashboard/school_info"
                      element={<AdminSchoolInfo schoolInfo={schoolInfo} />}
                    />

                    <Route
                      path="/admin/dashboard/people"
                      element={<AdminPeople />}
                    />
                    <Route
                      path="/admin/dashboard/people/*"
                      element={<AdminPeopleEdit />}
                    />

                    <Route
                      path="/admin/dashboard/articles"
                      element={<AdminArticles />}
                    />
                    <Route
                      path="/admin/dashboard/media"
                      element={<AdminMedia />}
                    />
                  </Route>
                </Route> */}

                  <Route path="*" element={<NotFound />} />
                </Routes>
              </AnimatePresence>

              <Footer schoolInfo={schoolInfo} />
            </AdminContext.Provider>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
