import { motion } from "framer-motion";
import { usePeople } from "../queries/people";
import { Box, Container } from "@mui/system";
import {
  Button,
  Card,
  CardMedia,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";

import { Link, useLocation, useNavigate } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import not_found_image from "../images/404.png";
import { useState } from "react";

const parse = require('html-react-parser');

function LoadingBar() {
  return (
    <Box width={"100%"}>
      <LinearProgress color="warning" />
    </Box>
  );
}

function PersonMediaSlider({ media }) {
  const [autoPlay, setAutoPlay] = useState(true);

  return (
    <Box sx={{ margin: 3 }}>
      <Carousel
        dynamicHeight
        emulateTouch
        autoPlay={autoPlay}
        infiniteLoop
        interval={6000}
        onSwipeStart={() => setAutoPlay((prev) => !prev)}
        onClickThumb={() => setAutoPlay((prev) => !prev)}
        stopOnHover
        showThumbs={false}
        showStatus={false}
      >
        {media.map((item, index) => {
          if (item.file_type === "image") {
            return (
              <img
                key={`person-media-${index}`}
                alt={item.description}
                src={item.url}
                style={{ maxHeight: "50vh", width: "auto" }}
              />
            );
          } else {
            return (
              <video
                key={`person-media-${index}`}
                src={item.url}
                autoPlay
                loop
                disablePictureInPicture
                x-webkit-airplay="deny"
                disableRemotePlayback
                playsInline
                controls
                controlsList="nodownload"
                style={{maxHeight:"50vh", maxWidth:"auto"}}
              />
            );
          }
        })}
      </Carousel>
    </Box>
  );
}

export function PeopleDetails() {
  const location = useLocation();
  const person = usePeople(location.pathname.split("/").reverse()[1]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {person.isLoading ? <LoadingBar /> : <></>}

      <Container style={{ marginTop: 8}}>
      <Button
          component={Link}
          to="/content/people"
          variant="info"
          sx={{ textTransform: "none" , marginBottom:5}}
        >
          <Typography fontSize={12}>
            <i>Πισω στoυς Ανθρώπους</i>
          </Typography>
        </Button>
        <Grid container justifyContent={"center"} spacing={4} maxWidth="95vw">
          {person.isError ? (
            <Box
              component={"img"}
              src={not_found_image}
              sx={{ width: "50%", height: "50%" }}
            />
          ) : (
            <></>
          )}
          {person.data ? (
            <>
              <Grid
                item
                md={3}
                xs
                sx={{
                  overflowWrap: "break-word",
                  textAlign:{xs:"center" , md:'left'}
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    borderRight: {
                      md: "2px solid #9B8F98",
                    },

                    borderBottom: { xs: "2px solid #9B8F98", md: "none" },
                  }}
                >
                  {person.data.name} 
                  <br />
                  {person.data.surname}
                </Typography>
              </Grid>
              <Grid item md={9} sx={{ textAlign: "center" }}>
                <Typography>{parse(person.data?.bio)}</Typography>
              </Grid>
            </>
          ) : (
            <></>
          )}
          <Grid item md={12}>
            {person.data?.media ? (
              <PersonMediaSlider media={person.data.media} />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Container>
    </motion.div>
  );
}

export function PeopleList({ peopleData, detailsUrl }) {
  const navigate = useNavigate();
  return (
    <Container sx={{ marginBottom: 10, marginTop: 5 }}>
      <Grid container spacing={3} justifyContent={"center"} position="relative">
        {peopleData?.map((person, index) => (
          <Grid
            item
            key={`person-${index}`}
            // md={5}
            sx={{
              textAlign: "center",
            }}
          >
            <Card
              key={`test-${index}`}
              sx={{
                "&:hover": { cursor: "pointer", transform: "scale(1.01)" },
                transition: "0.1s all linear",
              }}
              onClick={() => navigate(`${detailsUrl}/${person.slug}/`)}
            >
              <Box
                position={"relative"}
                sx={{
                  "&:hover .person-name": {
                    background: "rgba(0,0,0,0.25)",
                    //borderLeft:'none',
                  },
                }}
              >
                <CardMedia
                  component={"img"}
                  sx={{
                    height: 300,
                    width: 300,
                    objectFit: "contain",
                    top: "50%",
                    backgroundColor: "black",
                  }}
                  src={person?.media[0]?.url}
                />
                <Box
                  className="person-name"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    bgcolor: "rgba(0, 0, 0, 0.65)",
                    color: "white",
                    padding: "10px",
                    transition: "0.1s all ease-in",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "25px !important",
                      position: "relative",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    {person.name}
                    <br />
                    {person.surname}
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export function People() {
  const people = usePeople();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {people.data ? <PeopleList peopleData={people.data} detailsUrl={'/content/people'}/> : <LoadingBar />}
    </motion.div>
  );
}
