import axios from "axios";
import { useQuery } from "react-query";
import { useMutation } from "react-query";

export const usePeople = (slug = "") =>
  useQuery(
    ["people", slug],
    () => axios.get(`/api/people/${slug}`).then(({ data }) => data),
    { refetchOnMount: false }
  );

export const useAdminPeople = (data) =>
  useQuery([],
    (data) =>  {
      console.log(data)
      return axios
        .get(`/api/people/${data[0]}`, {
          headers: { authorization: `Bearer ${data[1]}` },
        })
        .then(({ data }) => data)}
      ,
    { refetchOnMount: false, refetchOnWindowFocus:false, refetchInterval:null }
  );

export const useDeletePeople = (onSuccess, onError) =>
  useMutation(
    ["delete-person"],
    ({ slug, token }) =>
      axios
        .delete(`/api/people/${slug ? `${slug}` : ''}`, {
          headers: { authorization: `Bearer ${token}` },
        })
        .then(({ data }) => data),
    {
      onSuccess,
      onError,
    }
  );

export const useUpdatePerson = (onSuccess, onError) =>
  useMutation(
    (data) => {
      console.log(data)
     
      axios
        .patch(`/api/people/${data[0]}/`, data[1], {
          headers: { token: `Bearer ${data[2]}` },
        })
        .then(({ data }) => data)
      },
    {
      onSuccess,
      onError,
    }
  );
