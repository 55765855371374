import { useContext } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { AdminContext } from "../../App";
import { Box, Button, Typography } from "@mui/material";

import AdminLogIn, { getLocalStorage } from "./AdminLogIn";
import { useLogout } from "../../queries/admin";

export function AdminBar() {
  const { adminIsLoggedIn } = useContext(AdminContext);
  const navigate = useNavigate();

  const onSuccess = () => {
    navigate('/')
  }

  const onError = (err) => console.log(err)
  const logout = useLogout(onSuccess, onError);


  const handleLogOut = () => {
    logout.mutate({refresh: getLocalStorage('moragemoudance-refresh')})
  }

  return adminIsLoggedIn.loggedIn ? (
    <Box
      sx={{
        backgroundColor: "green",
        position: "relative",
        zIndex: 999,
        justifyContent:'center',
        width:"100%",
        textAlign:'center'
      }}
      
    >
      {" "}
      <Typography color="white">
        <b>Συνδεδεμένος ως διαχειριστής</b>
      </Typography>
      <Button
        component={Link}
        to="/admin/dashboard"
        variant="info"
        sx={{ color: "white" }}
      >
        ΣΕΛΙΔΑ ΔΙΑΧΕΙΡΙΣΗΣ
      </Button>
      <Button
        component={Link}
        to="/admin/dashboard"
        variant="info"
        sx={{ color: "white" }}
        onClick={handleLogOut}
      >
       ΑΠΟΣΥΝΔΕΣΗ
      </Button>
    </Box>
  ) : (
    <></>
  );
}

export default function Admin() {
  const { adminIsLoggedIn } = useContext(AdminContext);

  return adminIsLoggedIn.loggedIn ? (
    <Outlet context={[adminIsLoggedIn]} />
  ) : (
    <AdminLogIn />
  );
}
