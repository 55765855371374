import { motion } from "framer-motion";

import {
  Box,
  Container,
  LinearProgress,
  Modal,
  Pagination,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { useMedia } from "../queries/media";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useState } from "react";
import { useNavigate, useLocation, Outlet, Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100vw",
  background: "black",
  boxShadow: 24,
  minWidth: "90vw",
  maxHeight: "90vh",
  overflow:'auto'
};

function LoadingBar() {
  return (
    <Box width={"100%"}>
      <LinearProgress color="warning" />
    </Box>
  );
}

export function MediaDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const mediaItem = useMedia("", location.pathname.split("/").reverse()[1] + '/');

  const handleClose = () => navigate("");
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {mediaItem.data ? (
        <Modal
          disableEnforceFocus
          disableAutoFocus
          open={true}
          onClose={handleClose}
        >
          <Box style={modalStyle} sx={{  }}>
            <Box width={"100%"} sx={{ textAlign: "right" }}>
              <Button onClick={handleClose}>
                <CloseIcon color="white" />
              </Button>
            </Box>

            <Grid container textAlign="center" justifyContent={"center"}>
              <Grid item>
                <Typography color={"white"}>{mediaItem.data.title}</Typography>
              </Grid>

              <Grid item xs={12}>
                {mediaItem.data.file_type === "image" ? (
                  <Box
                    component="img"
                    src={mediaItem.data.url}
                    sx={{ p: 2, maxWidth: "100vw" }}
                  />
                ) : (
                  <Box
                    component="video"
                    controls
                    src={mediaItem.data.url}
                    sx={{ p: 2, maxWidth: "100vw", maxHeight: "50vh" }}
                  />
                )}
              </Grid>
              {mediaItem.data.description ? (
                <Grid item p={2}>
                  <Typography color="white" fontSize={15}>
                    {mediaItem.data.description}
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}

              {mediaItem.data.people.length > 0 ? (
                <Grid item p={2}>
                  <Typography color="white" fontSize={15}>
                    {}
                    Σε αυτή τη φωτογραφία:
                    <br />
                    {mediaItem.data.people?.map((person, index) => (
                      <Button
                        key={`person-link-${index}`}
                    
                        component={Link}
                        sx={{ fontSize: 15 , textTransform: "none" }}
                        to={`/content/people/${person.slug}/`}
                      >
                        <b>
                          {person.name} {person.surname}
                        </b>
                      </Button>
                    ))}
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}

              {mediaItem.data.articles.length > 0 ? (
                <Grid item p={2}>
                  <Typography color="white" fontSize={15}>
                    {}
                    Σχετικό περιεχόμενο:
                    <br />
                    {mediaItem.data.articles?.map((article, index) => (
                      <Button
                        key={`article-link-${index}`}
                        component={Link}
                        sx={{ fontSize: 15 , textTransform: "none" }}
                        to={`/content/news/${article.slug}`}
                      >
                        <b>
                          {article.title}
                        </b>
                      </Button>
                    ))}
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}

              <Grid item p={2} xs={12}>
                <Typography color={"white"} fontSize={12}>
                  Αναρτήθηκε στις{" "}
                  <i>{mediaItem.data.date_created.split("T")[0]}</i>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      ) : (
        <></>
      )}
    </motion.div>
  );
}

export function Media() {
  const [currentPage, setCurrentPage] = useState(1);
  const media = useMedia(currentPage);
  const navigate = useNavigate();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {media.isSuccess ? <></> : <LoadingBar />}
      {media.data ? (
        <Container style={{ marginTop: 20 }}>
          <Box sx={{ padding: 1 }}>
            <Pagination
              sx={{ textAlign: "center" }}
              count={media.data.total_pages}
              defaultPage={1}
              onChange={(e, value) => setCurrentPage(value)}
              page={currentPage}
            />
          </Box>
          <Box width={"100%"} textAlign="center" sx={{ marginTop: 5 }}>
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 0: 1, 300: 2, 900: 3 }}
            >
              <Masonry>
                {media.data?.results?.map((item, index) => (
                  <Box
                    key={`media-${index}`}
                    sx={{
                      margin: 1,
                      cursor: "pointer",
                      transition: "0.1s all linear",
                      "&:hover": { transform: "scale(1.01)" },
                    }}
                    onClick={() => navigate(`/content/media/${item.id}/`)}
                  >
                    {item.file_type === "image" ? (
                      <img src={item.url} style={{ width: "100%" }} alt={item.description} />
                    ) : (
                      <Box
                        sx={{
                          width: "100%",
                          height: "100",
                          backgroundColor: "black",
                        }}
                      >
                        {" "}
                        <Typography color="white" fontSize={25}>
                          Video
                          <br />
                          <b>{item.title}</b>
                        </Typography>
                      </Box>
                    )}
                  </Box>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </Box>
        </Container>
      ) : (
        <></>
      )}
      <Outlet />
    </motion.div>
  );
}
