import { useEffect, useRef, useCallback, useContext } from "react";
import { Button, Box, TextField, Typography, Container } from "@mui/material";

import { AdminContext } from "../../App";
import { useVerify, useLogin } from "../../queries/admin";
import { useNavigate } from "react-router-dom";

function setLocalStorage(key, value) {
  try {
    window.localStorage.setItem(key, value);
  } catch (e) {
    console.log(e);
  }
}

export function getLocalStorage(key) {
  try {
    let value = window.localStorage.getItem(key);
    return value;
  } catch (e) {
    return undefined;
  }
}

export default function AdminLogIn() {
  const { adminIsLoggedIn, setAdminIsLoggedIn } = useContext(AdminContext);
  const usernameRef = useRef("");
  const passwordRef = useRef("");
  const navigate = useNavigate();

  const onLoginSuccess = (data) => {
    setAdminIsLoggedIn(true);
    setLocalStorage("moragemoudance-token", data["access_token"]);
    setLocalStorage("moragemoudance-refresh", data["access_token"]);
  };
  const login = useLogin(onLoginSuccess);

  const onVerifySuccess = (data) => {
    setAdminIsLoggedIn((prev) => ({
      ...prev,
      loggedIn: true,
      token: getLocalStorage("moragemoudance-token"),
    }));
    //navigate("/admin/dashboard");
  };
  const verify = useVerify(onVerifySuccess);

  const handleVerify = (refreshToken) => {
    verify.mutate({
      token: refreshToken,
    });
  };

  useEffect(() => {
    if (getLocalStorage("moragemoudance-refresh")) {
      handleVerify(getLocalStorage("moragemoudance-refresh"));
    }
  }, [adminIsLoggedIn]);

  const handleSubmit = useCallback(
    (e) => {
      login.mutate({
        username: usernameRef.current,
        password: passwordRef.current,
      });
    },
    [login, usernameRef, passwordRef]
  );

  return (
    <Container maxWidth="xs">
      <Box>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            variant="standard"
            onChange={(e) => (usernameRef.current = e.target.value)}
            error={!!login.error?.response.data?.["non_field_errors"]}
            helperText={login?.error?.response.data?.["non_field_errors"]}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            variant="standard"
            error={!!login?.error?.response.data?.["non_field_errors"]}
            helperText={login?.error?.response.data?.["non_field_errors"]}
            onChange={(e) => (passwordRef.current = e.target.value)}
          />
        </Box>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSubmit}
        >
          <Typography>ΣΥΝΔΕΣΗ ΔΙΑΧΕΙΡΙΣΤΗ</Typography>
        </Button>
      </Box>
    </Container>
  );
}
