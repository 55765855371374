import {
  Container,
  Box,
  LinearProgress,
  Grid,
  Typography,
  Button,
  Pagination,
} from "@mui/material";
import { motion } from "framer-motion";
import { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useArticles } from "../queries/articles";
import { Carousel } from "react-responsive-carousel";

const parse = require('html-react-parser');


function LoadingBar() {
  return (
    <Box width={"100%"}>
      <LinearProgress color="warning" />
    </Box>
  );
}

function parseDate(dateString) {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("el-gr", options);
}

function NewsMediaSlider({ media }) {
  const [autoPlay, setAutoPlay] = useState(true);

  return (
    <Box sx={{ margin: 3, height: "max-content" }}>
      <Carousel
        dynamicHeight
        emulateTouch
        autoPlay={autoPlay}
        infiniteLoop
        interval={6000}
        onSwipeStart={() => setAutoPlay((prev) => !prev)}
        onClickThumb={() => setAutoPlay((prev) => !prev)}
        stopOnHover
        showThumbs={false}
        showStatus={false}
      >
        {media.map((item, index) => {
          if (item.file_type === "image") {
            return (
              <img
                mediaurl={`/content/media/${item.id}/`}
                key={`person-media-${index}`}
                alt={item.description}
                src={item.url}
                style={{ maxHeight: "50vh", width: "auto" }}
                onClick={() => console.log("ok")}
              />
            );
          } else {
            return (
              <video
                mediaurl={`/content/media/${item.id}/`}
                key={`person-media-${index}`}
                src={item.url}
                autoPlay={false}
                loop
                disablePictureInPicture
                x-webkit-airplay="deny"
                disableRemotePlayback
                playsInline
                controls
                controlsList="nodownload"
                style={{ maxHeight: "50vh", maxWidth: "auto" }}
              />
            );
          }
        })}
      </Carousel>
    </Box>
  );
}

export function NewsDetails() {
  const location = useLocation();
  const article = useArticles("", location.pathname.split("/").reverse()[0]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Container sx={{ marginBottom: 5, marginTop: 1 }}>
        <Button
          component={Link}
          to="/content/news"
          variant="info"
          sx={{ textTransform: "none" }}
        >
          <Typography fontSize={12}>
            <i>Πισω στα Nέα & Ανακοινώσεις</i>
          </Typography>
        </Button>
        <Grid container textAlign={"center"} width="100%">
          <Grid item xs={12}>
            <Typography variant="h5">{article.data?.title}</Typography>
          </Grid>
          <Grid item xs={6} textAlign={"left"}>
            <Typography fontSize={12}>
              <i>{article.data?.author}</i>
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign={"right"}>
            <Typography fontSize={12}>
              <i>{parseDate(article.data?.date_created)}</i>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography sx={{ borderTop: "2px solid #9B8F98" }} pt={5}>
              {parse(article.data?.content ? article.data?.content : '')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {article.data?.media ? (
              <NewsMediaSlider media={article.data?.media} />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Container>
    </motion.div>
  );
}

export function NewsList({ newsData, detailsUrl, currentPage, setCurrentPage }) {
  const navigate = useNavigate();
  return (
    <Container sx={{ marginBottom: 10, marginTop: 2 }}>
      <Box sx={{ padding: 1 }}>
        <Pagination
          sx={{ textAlign: "center" }}
          count={newsData.total_pages}
          defaultPage={1}
          onChange={(e, value) => setCurrentPage(value)}
          page={currentPage}
        />
      </Box>
      <Grid container direction={"column"} position="relative" spacing={3}>
        {newsData.results.map((article, index) => (
          <Grid item key={`article-${index}`}>
            <Box
              sx={{
                cursor: "pointer",
                transition: "0.1s all ease-in",
                "&:hover": { transform: "scale(1.005)" },
              }}
              onClick={() => navigate(`${detailsUrl}/${article.slug}`)}
            >
              <Typography
                variant="h5"
                sx={{ borderLeft: "2px solid #9B8F98", padding: 2 }}
              >
                {article.title}
              </Typography>
              <Typography fontSize={12}>
                <i>Aναρτήθηκε {parseDate(article.date_created)}</i>
              </Typography>
              {article.author ? (
                <Typography fontSize={12}>
                  {" "}
                  <i>απο {article.author}</i>
                </Typography>
              ) : null}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default function News() {
  const [currentPage, setCurrentPage] = useState(1);
  const news = useArticles(currentPage);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {news.data ? (
        <NewsList
          newsData={news.data}
          detailsUrl={"/content/news"}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        <LoadingBar />
      )}
    </motion.div>
  );
}
