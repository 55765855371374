import axios from "axios";
import { useMutation, useQuery } from "react-query";

export const useLogin = (onSuccess) =>
  useMutation(
    (formData) => axios.post("/auth/login/", formData).then(({ data }) => data),
    {
      onSuccess,
    }
  );

export const useLogout = (onSuccess, onError) =>
  useMutation(
    (data) =>
      axios
        .post("/auth/logout/", data)
        .then(({ data }) => data),
    {
      onSuccess, // does nothing
      onError
    }
  );

export const useVerify = (onSuccess) =>
  useMutation(
    (data) => axios
        .post("/auth/token/verify/", data)
        .then(({ data }) => data)
    ,
    {
      onSuccess, // does nothing
      
    }
  );
