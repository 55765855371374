import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    background: {
      default: "#DDDBDC",
    },
  },
  typography: {
    h5: { fontFamily: "Cera Pro Medium", color: "rgb(90, 30, 33)" },
    menuLink: { fontFamily: "Vusillus Old Face"  },
    pageLink: {fontFamily: "Cera Pro Medium", letterSpacing:3},
    fontFamily: [
      "Cera Pro Light",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
        
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
  },
});
